import consumer from "./consumer"

consumer.subscriptions.create("StatusChangedChannel", {
  connected() {

  },

  disconnected() {
  },

  received(data) {
    window.location.href = '/orders'
  }
});
