import {updateQueryString} from "./util";

document.addEventListener('turbolinks:load', function() {
  $('#search-wrapper').on('submit', 'form', function(e){ search(e) });
});

function search(e) {
  e.preventDefault();
  const location = updateQueryString('q', $(e.target).serialize().split('=')[1]);
  window.location.href = location;
}
