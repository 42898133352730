import {updateQueryString} from "./util";

document.addEventListener('turbolinks:load', function() {
  $(document).on('click', '.time_filter', function(e){ filter_time_range(e) });
  $(document).on('click', '.time_filter_reset', function(e){ filter_time_range_reset(e) });

  $('.filters .dropdown-menu').click(function(e) {
    if($(e.target).hasClass('time_select')) {
      e.stopPropagation();
    }
  });
});

function filter_time_range(e) {
  const start_hour = $(e.target).closest('.dropdown-menu').find('.start_time').first().val();
  const end_hour = $(e.target).closest('.dropdown-menu').find('.end_time').first().val();

  const param = $(e.target).data('timeFields');
  const time_range = start_hour + '-' + end_hour;
  const location = updateQueryString(param, time_range);

  window.location.href = location;
}

function filter_time_range_reset(e) {
  const param = $(e.target).data('timeFields');
  const location = updateQueryString(param, '');
  window.location.href = location;
}
