document.addEventListener('turbolinks:load', function () {
  imageInputPreview();
  whiteBoxFocusInput();
});

imageInputPreview = () => {
  $(".image-label input").on("change", function () {
    if (this.files && this.files[0]) {
      let reader = new FileReader();
      let imgSrc = $(this).closest(".image-label").find("img");

      reader.onload = (event) => {
        imgSrc.attr('src', event.target.result);
      };

      reader.readAsDataURL(this.files[0]); // convert to base64 string
    }
  });
};

whiteBoxFocusInput = () => {
  $(".form-group").on("click", function () {
    $(this).find("input").focus();
  });
};
