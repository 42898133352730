document.addEventListener('turbolinks:load', function() {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();

  fixSidebarHeight();
  window.onresize = fixSidebarHeight;
});

fixSidebarHeight = () => {
  $('#sidebar-content').css("min-height", window.innerHeight - 66);
}

goto = (url) => {
  window.location.href = url;
}
