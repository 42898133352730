import { Controller } from "stimulus";
import markerSVG from "../../assets/images/map/marker.svg";
import markerSelectedSVG from "../../assets/images/map/marker-selected.svg";

export default class extends Controller {
    static targets = [
        "map",
    ];

    connect() {
        if (typeof google !== "undefined") {
            this.initMap();
        }
    }

    initMap() {
        if (this.hasMapTarget) {
            this.map = new google.maps.Map(this.mapTarget, {
                center: {lat: 46.54, lng: 24.56},
                zoom: 14,
                disableDefaultUI: true
            });

            let markersAdded = false;
            google.maps.event.addListener(this.map, 'tilesloaded', () => {
                if (markersAdded)
                    return;

                this.addMarkers();
                markersAdded = true;
            });
        }
    }

    addMarkers() {
        this.markers = {}
        $(".map-marker").each((index, listItem) => {
            if (listItem && listItem.dataset.lat && listItem.dataset.lng && listItem.dataset.name && listItem.dataset.status !== "off_shift") {
                let marker = new google.maps.Marker({
                    position: this.getPosition(listItem),
                    map: this.map,
                    animation: google.maps.Animation.DROP,
                    icon: markerSVG
                });

                marker.addListener('click', () => {
                    this.selectMarker(listItem);
                });

                this.markers[listItem.dataset.name] = marker;
            }
        });

        $(".map-marker").on("click", (event) => {
            if (['a', 'button', 'svg', 'path'].indexOf(event.target.tagName.toLowerCase()) === -1)
                this.selectMarker(event.currentTarget);
        });

        this.startPeriodicUpdates();
    }

    selectMarker(listItem) {
        let isSelected = $(listItem).hasClass("selected");

        this.clearSelectedMarkers();

        if (!isSelected && listItem && listItem.dataset.lat && listItem.dataset.lng) {
            $(listItem).addClass("selected");

            this.markerSelected = new google.maps.Marker({
                position: this.getPosition(listItem),
                map: this.map,
                icon: markerSelectedSVG
            });

            let name = listItem.getAttribute('data-name');
            let status = listItem.getAttribute('data-status');


            let infoWindow = new google.maps.InfoWindow({
                content: `<div id="content"><h3>Driver Details</h3><p>Driver Name: ${name}</p><p>Status: ${status}</p></div>`
            });

            this.markerSelected.addListener('click', () => {
                infoWindow.open(this.map, this.markerSelected);
            });

            this.map.panTo(this.getPosition(listItem));
            this.map.setZoom(16);
        }
    }

    clearSelectedMarkers() {
        if (this.markerSelected) {
            this.markerSelected.setMap(null);
            this.markerSelected = null;
        }
        $(".map-marker.selected").removeClass("selected");
    }

    getPosition(element) {
        return {lat: parseFloat(element.dataset.lat), lng: parseFloat(element.dataset.lng)};
    }

    startPeriodicUpdates() {
        setInterval(() => {
            this.fetchAndUpdateMarkers();
        }, 30000);
    }

    fetchAndUpdateMarkers() {
        fetch('/drivers.json')
          .then(response => response.json())
          .then(data => {
              Object.values(this.markers).forEach(marker => marker.setMap(null));
              this.markers = {};
              data.forEach(driver => {
                  let listItem = document.querySelector(`.map-marker[data-name="${driver.full_name}"]`);
                  if (listItem) {
                      listItem.setAttribute('data-lat', driver.latitude);
                      listItem.setAttribute('data-lng', driver.longitude);
                      listItem.setAttribute('data-status', driver.status_to_s);
                  } else {
                      let newItem = document.createElement('tr');
                      newItem.classList.add('map-marker');
                      newItem.setAttribute('data-lat', driver.latitude);
                      newItem.setAttribute('data-lng', driver.longitude);
                      newItem.setAttribute('data-name', driver.full_name);
                      newItem.setAttribute('data-status', driver.status_to_s);
                      document.querySelector('tbody').appendChild(newItem);
                  }

                  let marker = new google.maps.Marker({
                      position: {lat: parseFloat(driver.latitude), lng: parseFloat(driver.longitude)},
                      map: this.map,
                      animation: google.maps.Animation.DROP,
                      icon: markerSVG
                  });

                  marker.addListener('click', () => {
                      this.selectMarker(listItem);
                  });

                  this.markers[driver.full_name] = marker;
              });
          })
          .catch(error => console.error('Error fetching driver data:', error));
    }
}

