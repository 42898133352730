import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "map",
    "pickupField",
    "deliveryField",
    "pickupLat",
    "pickupLng",
    "deliveryLat",
    "deliveryLng"
  ];

  connect() {
    if (typeof google !== "undefined") {
      this.initMap();
      this.pickupFieldTarget.addEventListener('click', () => {
        this.selectedInputField = "pickup_address";
      });
      this.deliveryFieldTarget.addEventListener('click', () => {
        this.selectedInputField = "delivery_address";
      });
    }
  }


  initMap() {
    if (this.hasMapTarget) {
      this.map = new google.maps.Map(this.mapTarget, {
        center: { lat: 46.54, lng: 24.56 },
        zoom: 14,
        disableDefaultUI: true
      });

      this.initAutocomplete(this.pickupFieldTarget);
      this.initAutocomplete(this.deliveryFieldTarget);

      this.marker = new google.maps.Marker({
        map: this.map,
        draggable: true,
        animation: google.maps.Animation.DROP,
        anchorPoint: new google.maps.Point(0, -29)
      })

      google.maps.event.addListener(this.marker, 'dragend', () => {
        this.geocodePosition(this.marker.getPosition());
      });
    }
  }

  geocodePosition(position) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({
      location: position
    }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (results[0]) {
          if (this.selectedInputField == 'pickup_address') {
            this.pickupFieldTarget.value = results[0].formatted_address
          }  else if (this.selectedInputField == 'delivery_address') {
            this.deliveryFieldTarget.value = results[0].formatted_address
          }
        }
      }
    });
  }


  initAutocomplete(inputField) {
    const autocomplete = new google.maps.places.Autocomplete(inputField);
    autocomplete.setFields(['address_component', 'geometry', 'icon', 'name']);
    autocomplete.addListener('place_changed', () => {
      this.placeChanged(inputField, autocomplete);
    });
  }

  placeChanged(inputField, autocomplete) {
    const place = autocomplete.getPlace();
    if (!place.geometry) {
      return;
    }

    if (place.geometry.viewport) {
      this.map.fitBounds(place.geometry.viewport)
    } else {
      this.map.setCenter(place.geometry.location)
      this.map.setZoom(17)
    }

    this.marker.setPosition(place.geometry.location)
    this.marker.setVisible(true)

    if (inputField === this.pickupFieldTarget) {
      this.pickupLatTarget.value = place.geometry.location.lat();
      this.pickupLngTarget.value = place.geometry.location.lng();
    } else if (inputField === this.deliveryFieldTarget) {
      this.deliveryLatTarget.value = place.geometry.location.lat();
      this.deliveryLngTarget.value = place.geometry.location.lng();
    }
  }
}
