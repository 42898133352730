import { Controller } from "stimulus";
import markerSVG from "../../assets/images/map/marker.svg";
import markerSelectedSVG from "../../assets/images/map/marker-selected.svg";

export default class extends Controller {
  static targets = [
    "map",
  ];

  connect() {
    if (typeof google !== "undefined") {
      this.initMap();
    }
  }

  initMap() {
    if (this.hasMapTarget) {
      this.map = new google.maps.Map(this.mapTarget, {
        center: {lat: 46.54, lng: 24.56},
        zoom: 14,
        disableDefaultUI: true
      });

      let markersAdded = false;
      google.maps.event.addListener(this.map, 'tilesloaded', () => {
        if (markersAdded)
          return;

        this.addMarkers();
        markersAdded = true;
      });
    }
  }

  ACCEPTED_STATUS = ['delivery', 'picked_up', 'newly_added']
  addMarkers() {
    this.markers = {}
    $(".map-marker").each((index, listItem) => {

      if (listItem && listItem.dataset.lat && listItem.dataset.lng && this.ACCEPTED_STATUS.includes(listItem.dataset.status)) {
        let marker = new google.maps.Marker({
          position: this.getPosition(listItem),
          map: this.map,
          animation: google.maps.Animation.DROP,
          icon: markerSVG
        });

        marker.addListener('click', () => {
          this.selectMarker(listItem);
        });

        this.markers[listItem.dataset.phone_number] = marker;
      }
    });

    $(".map-marker").on("click", (event) => {
      if (['a', 'button', 'svg', 'path'].indexOf(event.target.tagName.toLowerCase()) === -1)
        this.selectMarker(event.currentTarget);
    });
  }

  selectMarker(listItem) {
    let isSelected = $(listItem).hasClass("selected");

    this.clearSelectedMarkers();

    if (!isSelected && listItem && listItem.dataset.lat && listItem.dataset.lng) {
      $(listItem).addClass("selected");

      this.markerSelected = new google.maps.Marker({
        position: this.getPosition(listItem),
        map: this.map,
        icon: markerSelectedSVG
      });
      let driver = listItem.getAttribute('data-driver') || 'Not yet assigned!';
      let status = listItem.getAttribute('data-status');
      let picked_up = listItem.getAttribute('data-picked-up') || 'Not yet picked up!';
      let pickup_address = listItem.getAttribute('data-pickup-address');
      let delivery_address = listItem.getAttribute('data-delivery-address');
      let phone_number = listItem.getAttribute('data-phone-number');

      let infoWindow = new google.maps.InfoWindow({
        content: `<div id="content">
                    <h3>Order Details</h3>
                    <p>Pickup: ${pickup_address}</p>
                    <p>Delivery: ${delivery_address}</p>
                    <p>Phone: ${phone_number}</p>
                    <p>Status: ${status}</p>
                    <p>Driver Name: ${driver}</p>
                    <p>Picked up at: ${picked_up}</p>
                  </div>`
      });

      this.markerSelected.addListener('click', () => {
        infoWindow.open(this.map, this.markerSelected);
      });

      this.map.panTo(this.getPosition(listItem));
      this.map.setZoom(16);
    }
  }

  clearSelectedMarkers() {
    if (this.markerSelected) {
      this.markerSelected.setMap(null);
      this.markerSelected = null;
    }
    $(".map-marker.selected").removeClass("selected");
  }

  getPosition(element) {
    return {lat: parseFloat(element.dataset.lat), lng: parseFloat(element.dataset.lng)};
  }
}

